import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import Login from '../../components/login/Login';
import { Container } from '../../components/shared';
import copy from '../../data/copy.json';
import { apiHeaderAuth } from '../../helpers';
import { getTokenProps } from '../../interfaces';
import { FetchEstablishment, setUser } from '../../redux/actions';
import AppRouter from '../../router/AppRouter';
import { ApiUrl } from '../../utils';

interface AuthProps {
  setUser: Function;
  FetchEstablishment: Function;
}
/**
 *
 * @returns
 */

const Auth: FC<AuthProps> = ({ setUser, FetchEstablishment }) => {
  //
  const getSession: getTokenProps | false = JSON.parse(
    sessionStorage.getItem('user_token') || 'false'
  );
  //
  const [verified, setVerified] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  //
  const getUser = (token: string) => {
    const API = ApiUrl + `establishment/user`;
    setLoading(true);
    axios
      .get(API, { headers: apiHeaderAuth(token) })
      .then(response => {
        //API REQUEST
        console.log('getUser', response.data);
        if (response.data.length > 0) {
          if (getSession) {
            setUser({ ...response.data[0], auth: getSession });
          } else {
            setUser(response.data[0]);
          }
          FetchEstablishment(token);
          //sessionStorage.setItem('user', JSON.stringify(response.data));
          setErrorMessage('');
        } else {
          setVerified(false);
          setErrorMessage('user not found');
        }
        setLoading(false);
      })
      .catch(error => {
        // eslint-disable-next-line no-self-assign
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (getSession) {
      //Session exists, show homepage
      getUser(getSession.access_token);
      setLoading(false);
      setVerified(false);
    } else if (!getSession) {
      //Session doesn't exists, login
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verified]);

  if (loading) {
    return <p>{copy.general.loading}</p>;
  } else if (verified) {
    return (
      <Router>
        <Switch>
          <Route path="/login">
            <title> Rapio | Login</title>
            {errorMessage.length > 0 && <h1>{errorMessage}</h1>}
            <Login
              setVerified={setVerified}
              loading={loading}
              setLoading={setLoading}
              getUser={getUser}
            />
          </Route>
          <Route path="*">
            <Container>
              <Redirect
                to={{
                  pathname: '/login'
                }}
              />
              {copy.general.redirect.login}
            </Container>
          </Route>
        </Switch>
      </Router>
    );
  } else {
    return <AppRouter />;
  }
};

const mapDispatchActionCreator = {
  setUser,
  FetchEstablishment
};

export default connect(null, mapDispatchActionCreator)(Auth);

// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   min-height: 100vh;
// `;
