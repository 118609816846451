import { useState, useMemo, useEffect } from 'react';
import { Establishment } from '../../interfaces';
import { SeatProps } from '../../interfaces/Seat';

export const useSeat = (
  EstablishmentsData: Establishment,
  seatsData: SeatProps[]
) => {
  const [text, setText] = useState('');
  const [orders, setOrders] = useState();
  const [filterData, setFilderData] = useState<SeatProps[]>();
  const [filterItem, setFilterItem] = useState('all');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ data: [] });
  const [resend, setResend] = useState(true);
  const [message, setMessage] = useState('');
  /**
   * Listen to establishment channel
   */
  useMemo(
    () =>
      EstablishmentsData.id &&
      window.Echo.channel('Rapio.' + EstablishmentsData.id).listen(
        'OrderPushed',
        (e: any) => {
          console.log('update', e);
          setResend(true);
        }
      ),
    [EstablishmentsData.id]
  );
  /**
   *
   */
  useEffect(() => {
    let filteredData = seatsData;
    if (filterItem !== 'all') {
      filteredData = seatsData.filter(
        filter =>
          filter.orders.length > 0 &&
          filter.orders.some(
            (order: any) => order[0].payment_method === filterItem.toLowerCase()
          )
      );
    }
    setFilderData(filteredData);
  }, [seatsData, filterItem]);

  useEffect(() => {
    if (search.length > 0) {
      setFilderData(
        seatsData.filter(seat =>
          seat.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilderData(seatsData);
    }
  }, [search, seatsData]);

  return {
    text,
    setText,
    orders,
    setOrders,
    filterData,
    setFilderData,
    filterItem,
    setFilterItem,
    loading,
    setLoading,
    data,
    setData,
    resend,
    setResend,
    message,
    setMessage,
    search,
    setSearch
  };
};
