import { AlertProp } from '../../interfaces/Alert';
import { randomString } from '../../utils';

const initialState: any = [];

const Alert = (state = initialState, action: any) => {
  console.log('alert reducer');
  switch (action.type) {
    case 'SET_ALERT': {
      state = [{ ...action.payload }, ...state];
      return state;
    }
    case 'REDUCE_ALERT': {
      //console.log('state', state);
      // console.log('state slice', state.slice(0, -1));
      const sliced = state.slice(1, state.length);
      state = sliced;
      return state;
    }
    case 'REMOVE_ALERT': {
      state = state.filter(
        (filter: AlertProp) =>
          filter.id?.toLowerCase() !== action.payload.toLowerCase()
      );
      console.log('state', state);
      return state;
    }
    case 'CLEAR_ALERT': {
      state = initialState;
      return state;
    }
    default:
      return state;
  }
};

export default Alert;
