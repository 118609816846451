import { useEffect, useState } from 'react';
import { Establishment } from '../../interfaces';
import { OrderProps } from '../../interfaces/Order';
import { groupedProps, tabProps } from './Orders.Interface';
import { parseData } from './Order.Functions';
import { OrderReducerPayload } from '../../redux/reducers/Orders.Reducer';

const useOrders = (
  EstablishmentsData: Establishment,
  orders: OrderReducerPayload
) => {
  /**
   * Status
   */
  const [selectedTab, setSelectedTab] = useState<keyof typeof tabProps>(
    'waiting'
  );
  const [groupedOrders, setGroupedOrders] = useState<groupedProps>({
    waiting: orders.response,
    prepairing: orders.response,
    ready: orders.response,
    all: orders.response
  });
  const [selectedOrder, setSelectedOrder] = useState<OrderProps | null>();
  const [deliveryTime, setDeliveryTime] = useState(
    EstablishmentsData.delivery_serve_time
  );

  /**
   * Effects
   */
  useEffect(() => {
    if (groupedOrders.waiting && groupedOrders.waiting.length > 0) {
      console.log(
        'groupedOrders.waiting.length > 0',
        groupedOrders.waiting.length
      );
      setSelectedOrder(groupedOrders.waiting[0]);
    } else if (
      groupedOrders.prepairing &&
      groupedOrders.prepairing.length > 0
    ) {
      setSelectedTab('prepairing');
      setSelectedOrder(groupedOrders.prepairing[0]);
    } else if (groupedOrders.ready && groupedOrders.ready.length > 0) {
      setSelectedTab('ready');
      setSelectedOrder(groupedOrders.ready[0]);
    } else {
      // setSelectedTab('all');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedOrders]);
  /**
   *
   */
  useEffect(() => {
    const data = parseData(orders.response);
    setGroupedOrders(data);
    if (orders.response.length < 1) {
      setSelectedOrder(null);
    }
  }, [orders.response]);

  return {
    selectedTab,
    setSelectedTab,
    groupedOrders,
    setGroupedOrders,
    selectedOrder,
    setSelectedOrder,
    deliveryTime,
    setDeliveryTime
  };
};

export default useOrders;
