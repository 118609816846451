import React, { useState, useEffect } from 'react';
import { apiHeaderAuth } from '../../../helpers';
import { useGet } from '../../../hooks';

type dateType = {
  day: number;
  month: number;
  year: number;
};

export const useOrderHistory = (
  establishmentId: number,
  searchDate: dateType
) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '{}');

  const {
    getFetch,
    fetchResponse,
    fetchError,
    fetchErrorMessage,
    fetchLoading
  } = useGet(
    apiHeaderAuth(getToken.access_token),
    `establishment/${establishmentId}/orders/picked_up/date/day/${searchDate.day}/${searchDate.month}/${searchDate.year}`,
    true
  );

  useEffect(() => {
    console.log('fetch response', fetchResponse);
  }, [fetchResponse]);
  useEffect(() => {
    console.log('fetch error', fetchError);
  }, [fetchError]);

  return {
    getFetch,
    fetchResponse,
    fetchError,
    fetchErrorMessage,
    fetchLoading
  };
};
