//
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaArrowRight, FaBell, FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import styled from 'styled-components';
// config
import { ThemeColors } from '../config';

// containers
import {
  Orders,
  Addons,
  MenuItems,
  Products,
  SeatOrders,
  Seats,
  OrdersHistory,
  OrdersRefund,
  OrdersFast
} from '../containers';
// components

import {
  Column,
  Page,
  Row,
  Tag,
  Heading,
  Stats,
  Icon,
  MainButton,
  Settings,
  Post,
  OrdersEstablishments,
  OrdersDate,
  Nav,
  Modal,
  Header,
  Events,
  Establishments,
  Dashboard,
  AlertWrapper,
  Alert
} from '../components';

// custom Hooks
import { useSticky } from '../hooks/useSticky';
// Interface
import * as Interface from '../interfaces';
import { AlertProp } from '../interfaces/Alert';
//redux
import {
  clearAlert,
  FetchOrders,
  reduceAlert,
  removeAlert,
  saveEstablishment,
  selectEstablishment,
  setAlert
} from '../redux/actions';
import { OrderReducerPayload } from '../redux/reducers/Orders.Reducer';
import { broadcasting, listen } from '../services';
// Router
import OrderCounter from './OrderCounter';

interface AppRouterProps {
  FetchOrders: Function;
  saveEstablishment: Function;
  selectEstablishment: Function;
  alerts: AlertProp[];
  setAlert: Function;
  removeAlert: Function;
  reduceAlert: Function;
  clearAlert: Function;
  orders: OrderReducerPayload;
}
/**
 * Broadcasting
 */
broadcasting();

const AppRouter: React.FC<AppRouterProps> = ({
  FetchOrders,
  saveEstablishment,
  selectEstablishment,
  alerts,
  removeAlert,
  setAlert,
  reduceAlert,
  clearAlert,
  orders
}) => {
  const storedEstablishment = localStorage.getItem('establishment');

  const parsedEstablishment: Interface.Establishment = JSON.parse(
    storedEstablishment || '{}'
  );

  // eslint-disable-next-line prettier/prettier
  const [
    EstablishmentsData,
    setEstablishmentData
  ] = useState<Interface.Establishment>(parsedEstablishment);

  const [menuOpen, setMenuOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [width, setWidth] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [showAlerts, setShowAlerts] = useState(alerts.length > 0);
  //
  const playSound = (url: string) => {
    const audio = new Audio(url);
    audio.play();
  };
  //
  const alertsRef = useRef(null);
  /**
   * Listen to establishment channel
   */
  useMemo(
    () =>
      EstablishmentsData.id &&
      listen(EstablishmentsData.id, setUpdating, FetchOrders),
    [EstablishmentsData.id]
  );
  /**
   * Fetch orders
   */
  useEffect(() => {
    if (parsedEstablishment.id) {
      FetchOrders();
    }
    selectEstablishment(parsedEstablishment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('establishment', JSON.stringify(EstablishmentsData));
    selectEstablishment(EstablishmentsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EstablishmentsData]);

  /**
   * Handle websocket update
   */
  useEffect(() => {
    if (updating) {
      FetchOrders(EstablishmentsData.id);
      playSound('/assets/sounds/notification_5.mp3');
      setAlert({
        title: 'A new order!',
        message: 'Check your latest order at the order page.',
        type: 'success'
      });
      setTimeout(function () {
        setUpdating(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);

  useEffect(() => {
    setShowAlerts(alerts.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);
  //
  useEffect(() => {
    if (orders.error) {
      setAlert({
        title: 'Oops something went wrong fetching the orders',
        message: orders.errorResponse.message,
        type: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders.error, orders.errorResponse.message]);
  //
  useEffect(() => {
    if (!orders.firstLoad) {
      setAlert({
        title: 'Got latest orders',
        message: 'We successfully fetched the orders',
        type: 'success'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders.response]);
  //
  const { sticky } = useSticky(alertsRef, 30);
  //
  return (
    <Router>
      <Container>
        <AlertWrapper
          className={`sticky ${sticky ? 'sticky-on' : ''}`}
          show={showAlerts}
          ref={alertsRef}
        >
          <Row
            BColor="#ffffff"
            JContent="space-between"
            padding="8px"
            radius="4px"
          >
            <MainButton
              type="light"
              color={ThemeColors.primaryColor}
              action={() => clearAlert(!showAlerts)}
            >
              <FaTimes style={{ marginRight: '16px' }} /> Clear
            </MainButton>
            <MainButton
              type="light"
              color={ThemeColors.primaryColor}
              action={() => setShowAlerts(false)}
            >
              Hide <FaArrowRight style={{ marginLeft: '16px' }} />
            </MainButton>
          </Row>
          {alerts &&
            alerts.length > 0 &&
            alerts.map((alert: AlertProp, i: number) => (
              <Alert
                title={`${alert.title}`}
                message={alert.message}
                type={alert.type}
                id={alert.id || ''}
                show={i === 0 || i > alerts.length - 2}
                close={() => removeAlert(alert.id)}
              />
            ))}
        </AlertWrapper>
        <Nav
          open={menuOpen}
          activeAll={EstablishmentsData.id > 0}
          updating={updating}
          EstablishmentsData={EstablishmentsData}
        />
        <Header open={menuOpen} left={width}>
          {/* <BurgerMenu open={menuOpen} setOpen={setMenuOpen} /> */}
          <RapioLogo src="/logo512.png" />
          <Row JContent="space-between">
            {EstablishmentsData.id && (
              <Heading extraClasses="text-white">
                {EstablishmentsData.name}
              </Heading>
            )}
            <Row JContent="flex-end">
              <Tag show={updating}>
                Fetching new orders
                <Icon full={true} type="spinning" icon="Spinner" />
              </Tag>
              <OrderCounter />
              {/* <ProfileIcon>
                {EstablishmentsData &&
                  EstablishmentsData.name &&
                  EstablishmentsData.name[0]}
              </ProfileIcon> */}
              <NotifacitonWrapper
                onClick={() => alerts.length > 0 && setShowAlerts(!showAlerts)}
              >
                <FaBell
                  style={{
                    fontSize: 40,
                    padding: '8px',
                    transition: 'all 0.5s ease',
                    color: showAlerts
                      ? ThemeColors.primaryColor
                      : ThemeColors.primaryFontColor,
                    background: showAlerts
                      ? ThemeColors.primaryFontColor
                      : ThemeColors.primaryColor,
                    opacity: alerts.length > 0 ? 1 : 0.5,
                    borderRadius: '50%'
                  }}
                />
                {alerts.length > 0 && (
                  <Counter showAlerts={showAlerts}>{alerts.length}</Counter>
                )}
              </NotifacitonWrapper>
            </Row>
          </Row>
        </Header>
        <Page open={menuOpen} left={width}>
          <Switch>
            <Route path="/Stats">
              <title> Rapio | Stats</title>
              <Stats
                updating={updating}
                EstablishmentsData={EstablishmentsData}
              />
            </Route>
            <Route path="/Orders/refunded">
              <title> Rapio | Orders refunds</title>
              <OrdersRefund
                EstablishmentsData={EstablishmentsData}
                updating={updating}
              />
            </Route>
            <Route path="/Orders/history">
              <title> Rapio | Orders history</title>
              <OrdersHistory
                EstablishmentsData={EstablishmentsData}
                updating={updating}
              />
            </Route>
            <Route path="/Orders">
              <title> Rapio | Orders</title>
              <Content>
                {/* {showCompleted ? (
                  <OrdersDate
                    showCompleted={showCompleted}
                    setShowCompleted={setShowCompleted}
                    type="orders"
                    EstablishmentsData={EstablishmentsData}
                  />
                ) : (
                  <Orders
                    //showCompleted={showCompleted}
                    //setShowCompleted={setShowCompleted}
                    EstablishmentsData={EstablishmentsData}
                    updating={updating}
                  />
                )} */}
                {process.env.REACT_APP_THEME === 'club' ? (
                  <OrdersFast
                    EstablishmentsData={EstablishmentsData}
                    updating={updating}
                  />
                ) : (
                  <Orders
                    //showCompleted={showCompleted}
                    //setShowCompleted={setShowCompleted}
                    EstablishmentsData={EstablishmentsData}
                    updating={updating}
                  />
                )}
              </Content>
            </Route>
            <Route path="/Products/:product?">
              <title> Rapio | Products</title>
              <Products EstablishmentsData={EstablishmentsData} />
              {/* <ProductsList EstablishmentsData={EstablishmentsData} /> */}
            </Route>
            <Route path="/table/:session_id?">
              <SeatOrders />
            </Route>
            <Route path="/Tables">
              <title> Rapio | Tablets</title>
              {/* <Tables EstablishmentsData={EstablishmentsData} /> */}
              <Seats
                updating={updating}
                EstablishmentsData={EstablishmentsData}
              />
            </Route>

            <Route path="/Event">
              <title> Rapio | Events</title>
              <Events EstablishmentsData={EstablishmentsData} />
            </Route>
            <Route path="/Menu_items">
              <title> Rapio | Menu Items</title>

              <MenuItems EstablishmentsData={EstablishmentsData} />
            </Route>
            <Route path="/Establishments">
              <title> Rapio | Establishments</title>

              <OrdersEstablishments EstablishmentsData={EstablishmentsData} />
            </Route>
            <Route path="/logout">
              <title> Rapio | Loging out</title>
              <p>Logging out ...</p>
            </Route>
            <Route path="/settings/:selected?">
              <Settings
                EstablishmentsData={EstablishmentsData}
                setEstablishmentData={setEstablishmentData}
                updating={updating}
              />
            </Route>
            <Route path="/addons">
              <title> Rapio | Addons</title>

              <Addons EstablishmentsData={EstablishmentsData} />
            </Route>
            <Route
              path="/select_establishment/:establishment?"
              children={
                <Column>
                  <title> Rapio | Select Establishment</title>
                  <Establishments
                    setEstablishmentData={setEstablishmentData}
                    EstablishmentsData={EstablishmentsData}
                  />
                </Column>
              }
            />
            <Route path="/post/:slug?">
              <title> Rapio | Posts</title>
              <Post />
            </Route>
            <Route path="/Dashboard">
              <title> Rapio | Dashboard</title>

              <Dashboard
                EstablishmentsData={EstablishmentsData}
                updating={updating}
              />
            </Route>
            {process.env.NODE_ENV === 'development' && (
              <Route path="/components">
                <p> Components page</p>
              </Route>
            )}
            <Route>
              <Redirect
                to={{
                  pathname: '/Dashboard'
                }}
              />
            </Route>
          </Switch>
        </Page>
      </Container>
      {!parsedEstablishment.name && (
        <Modal closeModal={() => {}}>
          <title> Rapio | Establishment</title>
          <Establishments
            setEstablishmentData={setEstablishmentData}
            EstablishmentsData={EstablishmentsData}
          />
        </Modal>
      )}
    </Router>
  );
};

const mapStateToProps = (state: any) => {
  const { Alert, Orders } = state;
  return {
    alerts: Alert,
    orders: Orders
  };
};

const mapDispatchToProps = {
  FetchOrders,
  saveEstablishment,
  selectEstablishment,
  setAlert,
  removeAlert,
  reduceAlert,
  clearAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const RapioLogo = styled.img`
  width: 75px;
  border-radius: 50%;
  margin-right: 32px;
`;

const NotifacitonWrapper = styled.div`
  position: relative;
  margin: 0 16px;
`;

const Counter = styled.div<{ showAlerts: boolean }>`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: all 0.5s ease;
  background-color: ${props =>
    props.showAlerts ? ThemeColors.primaryColor : '#fff'};
  color: ${props => (props.showAlerts ? '#fff' : ThemeColors.primaryColor)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
