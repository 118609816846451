import { useEffect } from 'react';
import { config } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { usePut } from '../../hooks';
import { usePost } from '../../hooks/usePost';
import { Establishment } from '../../interfaces';
import { OrderProps } from '../../interfaces/Order';
import { determinStatus, parseData } from './Order.Functions';

const useOrdersData = (
  EstablishmentsData: Establishment,
  deliveryTime: any,
  selectedOrder: any,
  setSelectedOrder: Function,
  setGroupedOrders: Function,
  setAlert: Function,
  saveOrders: Function
) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '{}');
  /**
   *
   */
  const { sendPut, putResponse, putLoading, putError, errorResponse } = usePut(
    `establishment/${EstablishmentsData.id}/order/update/${selectedOrder?.id}`,
    apiHeaderAuth(getToken.access_token),
    {
      status:
        process.env.REACT_APP_THEME === 'club'
          ? 'pickedUp'
          : determinStatus(selectedOrder?.status),
      delivery_time: deliveryTime
    }
  );

  const { sendPost, postResponse, postError } = usePost(
    `establishment/${EstablishmentsData.id}/order/refund/${selectedOrder?.id}`,
    apiHeaderAuth(getToken.access_token),
    {}
  );
  //
  useEffect(() => {
    if (putResponse && putResponse.data && putResponse.data.data) {
      const data = parseData(putResponse.data.data);
      //console.log('response', putResponse.data.data);
      //saveOrders({ response: putResponse.data.data });
      setGroupedOrders(data);
      const findOrder = putResponse.data.data.find(
        (item: OrderProps) => item.id === selectedOrder?.id
      );
      if (findOrder) {
        setSelectedOrder(findOrder);
      } else {
        setSelectedOrder(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putResponse]);
  //
  useEffect(() => {
    if (putError) {
      setAlert({
        title: 'Something went wrong!',
        message: 'Please try again later',
        type: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponse, putError]);
  //
  useEffect(() => {
    if (postResponse && postResponse.data && postResponse.data.data) {
      const data = parseData(postResponse.data.data);
      //saveOrders({ response: postResponse.data.data });
      setGroupedOrders(data);
      const findOrder = postResponse.data.data.find(
        (item: OrderProps) => item.id === selectedOrder?.id
      );
      if (findOrder) {
        setSelectedOrder(findOrder);
      } else {
        setSelectedOrder(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postResponse]);
  //
  useEffect(() => {
    if (postError) {
      setAlert({
        title: 'Something went wrong!',
        message: 'Please try again later',
        type: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postError]);
  //
  return { sendPut, putResponse, putLoading, putError, sendPost };
};

export default useOrdersData;
