/**
 *
 * @param {*} id
 * @param {*} setUpdating
 * @param {*} fetch
 */
export const listen = (id, setUpdating, fetch) => {
  //
  const listenToChannel = 'Rapio.' + id;
  //
  console.log('listenToChannel', listenToChannel);
  //
  window.Echo.channel(listenToChannel).listen('OrderPushed', e => {
    //
    if (e.type && e.type === 'new') {
      console.log('update', e);
      console.log('resend');
      //
      setUpdating(true);
      //
    } else {
      console.log('update', e);
      console.log('fetch');
      //
      fetch(id);
    }
  });
};
