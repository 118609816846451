import React, { FC, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import {
  ChartCard,
  Column,
  Container,
  MainButton,
  OrderCard,
  OrderDetailCard,
  PageTitle,
  Row
} from '../../components';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { useGet } from '../../hooks';
import { usePost } from '../../hooks/usePost';
import { Establishment } from '../../interfaces';
import { OrderProps } from '../../interfaces/Order';
import { Blank, CardWrapper, ScrollContainer } from '../Order/Orders.View';

interface Props {
  location: any;
  establishment: Establishment;
}

type SeatRouteProps = {
  session_id: string;
};

type WithRouterProps = RouteComponentProps<SeatRouteProps>;

type AllProps = WithRouterProps & Props;

const SeatOrders: FC<AllProps> = ({ location, match, establishment }) => {
  //
  const session_id = match.params.session_id;
  //
  const [selectedOrder, setSelectedOrder] = useState<OrderProps | null>();
  const [orders, setOrders] = useState([]);
  //
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  //
  let history = useHistory();

  //
  const header = apiHeaderAuth(getToken.access_token);
  //
  const { sendPost, postResponse, postError, postLoading } = usePost(
    `establishment/${establishment.id}/seats/pay/cash/${selectedOrder?.id}`,
    header,
    {}
  );
  const {
    getFetch,
    fetchError,
    fetchErrorMessage,
    fetchLoading,
    fetchResponse
  } = useGet(
    header,
    `establishment/${establishment.id}/seats/show/${session_id}`
  );

  const parseOrders = (orders: any) => {
    console.log('orders', orders);
    let newOrders: any = [];
    if (location.state) {
      newOrders = orders.map((item: any) => (item.length > 0 ? item[0] : item));
    }

    return newOrders;
  };

  useEffect(() => {
    setOrders(parseOrders(location.state.orders));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (establishment.id) {
      getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishment]);

  useEffect(() => {
    if (fetchResponse.orders) {
      setOrders(parseOrders(fetchResponse.orders));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResponse]);

  return (
    <>
      <title> Tables | View Table</title>
      <Container>
        <Row>
          <PageTitle>View Table</PageTitle>
          <MainButton type="light" action={() => history.push('/tables')}>
            <FaArrowLeft style={{ marginRight: '16px' }} /> go back
          </MainButton>
        </Row>
        <Row AItems="flex-start">
          <ScrollContainer>
            <Column width="100%">
              {orders &&
                orders.map((item: OrderProps, index: number) => {
                  const active = item.id === selectedOrder?.id;
                  const colors = {
                    complete: ThemeColors.success,
                    prepairing: ThemeColors.warning,
                    waiting: ThemeColors.error
                  };
                  const activeColor = colors[item.status];
                  return (
                    <CardWrapper
                      onClick={() => setSelectedOrder(item)}
                      active={active}
                      borderColor={activeColor}
                    >
                      <OrderCard order={item} key={index} />
                    </CardWrapper>
                  );
                })}
            </Column>
          </ScrollContainer>

          <Row AItems="flex-start" margin="0 8px" width="60%">
            {selectedOrder ? (
              <OrderDetailCard
                order={selectedOrder}
                update={
                  selectedOrder.payment_method === 'cash'
                    ? () => sendPost(true)
                    : () => {}
                }
                btnText={
                  selectedOrder.payment_method === 'cash' ? 'Cash payed' : ''
                }
              />
            ) : (
              <ChartCard title="No order selected" width="100%" margin="8px 0">
                <Blank src="/assets/svg/blank.svg" />
              </ChartCard>
            )}
          </Row>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { Establishment } = state;
  return {
    establishment: Establishment
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SeatOrders)
);
